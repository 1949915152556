import React from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'
import EmfImage from '@elements/EmfImage'
import listArrow from '@images/icons/arrow-right.svg'

const Section = tw.section`relative h-full flex flex-col md:flex-row align-top xl:ml-offset-master `
const Column = tw.div`flex flex-col w-full md:w-1/2 `
const LeftColumn = styled(Column)`
	${tw`md:mt-spacingXl-lg xl:mt-spacingXl-master ml-offset-xs sm:ml-offset-lg md:ml-0`}
`
const AspectRatio = tw.div`aspect-w-16 aspect-h-12`
const RightColumn = tw(Column)`order-first md:order-last pr-offset-xs sm:pr-offset-lg md:pr-0 pb-offset-xs md:pb-0`
const DescriptionImage = styled(EmfImage)``
const HeadlineImage = styled(EmfImage)`
	${tw`md:-translate-x-spacingM-xs xl:-translate-x-spacingM-master md:w-[calc(100% + 60px)] xl:w-[calc(100% + 100px)]`}
`
const StyledHeadline = tw.h2`z-20 uppercase max-w-[340px] ml-offset-xs sm:ml-[10%] md:my-28 xl:max-w-[1000px]
text-[50px] leading-[50px]
xl:text-[70px] xl:leading-[70px] 
2xl:text-[100px] 2xl:leading-[100px] md:ml-16`
const StyledDescription = styled.div`
	${tw`relative font-futuraBook tracking-[1px] text-base sm:leading-[30px] sm:text-[20px] pt-16 md:pt-20 ml-offset-xs md:mx-9 xl:self-center text-dark max-w-[406px] w-8/12 xl:w-full`}

	a {
		${tw`relative font-futuraBold text-primary hover:text-black`}
	}
	ul {
		br {
			${tw`hidden`}
		}
		li {
			${tw`relative pl-6`}

			&::before {
				content: '';
				${tw`absolute left-0 w-3 h-3 bg-no-repeat bg-contain top-2`}
				background-image: url(${listArrow});
			}
		}
	}
`

const WpAcfOverlappingtextimageBlock = ({ data }) => {
	const { text, headline, leftImage, rightImage } = data.modulesOverlappingTextImage
	return (
		<Section className='emf-pb-spacing'>
			<LeftColumn>
				<AspectRatio>
					<DescriptionImage src={leftImage?.localFile} altText={leftImage?.altText} />
				</AspectRatio>
				{text && <StyledDescription>{parse(text)}</StyledDescription>}
			</LeftColumn>
			<RightColumn>
				{headline && <StyledHeadline className='headline-h2'>{parse(headline)}</StyledHeadline>}
				<AspectRatio>
					<HeadlineImage src={rightImage?.localFile} altText={rightImage?.altText} />
				</AspectRatio>
			</RightColumn>
		</Section>
	)
}
export default WpAcfOverlappingtextimageBlock
